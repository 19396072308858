import React, { useState, useEffect, useCallback } from "react";
import { withRouter } from "react-router-dom";
import TableTabs from "components/common/Table/tableTabs";
import { StyledTab } from "components/common/Table/stylesTabs";
import Counter from "components/common/TableList/counter";
import Table from "components/common/Table/table";
import AccountPayablesDownload from "./accountPayablesDownload";
import WorkticketSoloStatusChip from "components/ui/Worktickets/WorkticketSoloStatusChip";
import WorkticketField from "components/ui/Worktickets/WorkticketField";
import EnhancedTableToolbar from "components/common/Table/tableToolbar";
import ToolbarSelected from "components/common/Table/tableToolbarSelected";
import ToolbarDefault from "components/common/Table/tableToolbarDefault";
import AccountPayablesFilters from "./accountPayablesFilters";
import AccountPayablesBulkActions from "./accountPayablesBulkActions";
import CalendarFilters from "components/common/Filters/calendarFilters";
import FiltersAppliedList from "components/common/Filters/filtersAppliedList";
import { dateFormatField } from "components/util/timeFormat";
import AccountPayablesListActions from "./accountPayablesListActions";
import Box from "@material-ui/core/Box";
import LinearProgress from "@material-ui/core/LinearProgress";
import { cloneDeep } from "lodash";
import { billingAPTabOptions } from "constants.js";
import useStyles from "./styles";

import { useTableState, useTableDispatch } from "contexts/tableContext";
import {
  useBillingState,
  useBillingDispatch,
  getBillingList,
  getBillingExportList,
  searchBilling,
  getBillingListDataSet,
  getBillingExportListDataSet,
} from "contexts/billingApContext";
import {
  useFilterState,
  useFilterDispatch,
  getFilterData,
} from "contexts/filterContext";
import LoadingStateHorizontal from "components/common/LoadingStateHorizontal/LoadingStateHorizontal";

const columns = [
  {
    id: "id",
    label: "ID",
    format: "id",
    path: "",
  },
  {
    id: "user_name",
    label: "Subcontractor",
  },
  {
    id: "job",
    label: "Job",
    content: (row) => (
      <nobr>
        {row.job_number} - {row.job_description}
      </nobr>
    ),
    disableSort: true,
  },
  {
    id: "manager",
    label: "Manager",
    content: (row) => `${row.primary_manager ?? "N/A"}`,
    disableSort: true,
  },
  {
    id: "workticket_number",
    label: "Workticket",
    format: "link",
    content: (row) => `${row.workticket_number}${row.partial_suffix ?? ``}`,
    path: (row) =>
      row.workticket_id ? `/workticket/${row.workticket_id}` : ``,
  },
  {
    id: "workticket_summary",
    label: "WT Summary",
    content: (row) => (
      <WorkticketField field={row.workticket_summary} fieldLength={35} />
    ),
  },
  {
    id: "status",
    label: "Status",
    content: (row) => (
      <WorkticketSoloStatusChip
        status={row?.workticket_status ?? ""}
        overdue={row?.overdue ?? 0}
        archived={0}
      />
    ),
    disableSort: true,
  },
  { id: "gl_number", label: "GL" },
  { id: "workticket_start_date", label: "Due Date", format: "date" },
  {
    id: "workticket_sub_completed_date",
    label: "Completed Date",
    content: (row) =>
      row.workticket_sub_completed_date
        ? dateFormatField(row.workticket_sub_completed_date)
        : `-`,
  },
  {
    id: "workticket_completed_date",
    label: "Verified Date",
    content: (row) =>
      row.workticket_completed_date
        ? dateFormatField(row.workticket_completed_date)
        : `-`,
  },
  {
    id: "amount_due",
    label: "Payment",
    format: "money",
  },
];

const columnsReady = [
  {
    id: "id",
    label: "ID",
    format: "id",
    path: "",
    checkbox: {
      active: (row) => row.amount_due > 0 && row.user_name,
      tooltip: (row) =>
        !row.amount_due > 0 && !row.user_name
          ? "Payment should have a Subcontrator assigned and amount should be more than zero"
          : row.amount_due > 0
          ? row.user_name
            ? null
            : "Payment should have a Subcontrator assigned"
          : "Payment amount should be more than zero",
    },
  },
  {
    id: "user_name",
    label: "Subcontractor",
  },
  {
    id: "job",
    label: "Job",
    content: (row) => (
      <nobr>
        {row.job_number} - {row.job_description}
      </nobr>
    ),
    disableSort: true,
  },
  {
    id: "manager",
    label: "Manager",
    content: (row) => `${row.primary_manager ?? "N/A"}`,
    disableSort: true,
  },
  {
    id: "workticket_number",
    label: "Workticket",
    format: "link",
    content: (row) => `${row.workticket_number}${row.partial_suffix ?? ``}`,
    path: (row) =>
      row.workticket_id ? `/workticket/${row.workticket_id}` : ``,
  },
  {
    id: "workticket_summary",
    label: "WT Summary",
    content: (row) => (
      <WorkticketField field={row.workticket_summary} fieldLength={35} />
    ),
  },
  {
    id: "status",
    label: "Status",
    content: (row) => (
      <WorkticketSoloStatusChip
        status={row?.workticket_status ?? ""}
        archived={0}
      />
    ),
    disableSort: true,
  },
  { id: "gl_number", label: "GL" },
  { id: "workticket_start_date", label: "Due Date", format: "date" },
  {
    id: "workticket_sub_completed_date",
    label: "Completed Date",
    content: (row) =>
      row.workticket_sub_completed_date
        ? dateFormatField(row.workticket_sub_completed_date)
        : `-`,
  },
  {
    id: "workticket_completed_date",
    label: "Verified Date",
    content: (row) =>
      row.workticket_completed_date
        ? dateFormatField(row.workticket_completed_date)
        : `-`,
  },
  {
    id: "amount_due",
    label: "Payment",
    format: "money",
  },
];

const columnsExport = [
  {
    id: "id",
    label: "ID",
    format: "id",
    checkbox: {
      active: (row) => false,
    },
  },
  {
    id: "file_name",
    label: "File Name",
  },
  {
    id: "created_at",
    label: "Date",
    format: "date",
  },
  {
    id: "created_by",
    label: "Created By",
    content: (row) =>
      `${row.export_file[0].user.first_name} ${row.export_file[0].user.last_name}`,
    disableSort: true,
  },
  {
    id: "file",
    label: "",
    content: (row) => <AccountPayablesDownload fileId={row.id} />,
    style: { width: 150 },
    disableSort: true,
  },
];

const SEARCH_INDEX = "9999";
const FILE_INDEX = "8888";

const AccountPayablesTable = (props) => {
  const classes = useStyles();
  const [loadingSearch, setLoadingSearch] = useState(false);
  const [loadingMore, setLoadingMore] = useState(false);
  const { billingListLoading, billingList, billingCount } = useBillingState();
  const { hasRowsClickable, selected, currentTab } = useTableState();
  const filterState = useFilterState();
  const dispatchTable = useTableDispatch();
  const dispatchBilling = useBillingDispatch();
  const dispatchFilters = useFilterDispatch();

  useEffect(() => {
    const moduleName = window.location.pathname.split("/")[1];
    if (localStorage.getItem(`filter_${moduleName}`)) {
      dispatchFilters({
        type: "SET_FILTERS_CORE",
        filters: JSON.parse(
          localStorage.getItem(
            `filter_${window.location.pathname.split("/")[1]}`
          )
        ),
      });
    }
    if (localStorage.getItem(`filter_date_${moduleName}`)) {
      const dataDate = JSON.parse(
        localStorage.getItem(`filter_date_${moduleName}`)
      );
      dispatchFilters({
        type: "SET_DATE",
        ...dataDate,
      });
    }
    dispatchFilters({
      type: "SET_LOADING",
      isLoadingFilters: false,
    });
  }, [dispatchFilters]);

  useEffect(() => {
    const fetchData = async () => {
      dispatchBilling({
        type: "SET_LOADING",
        billingListLoading: true,
      });

      const filterData = getFilterData(filterState);

      const local_tab = localStorage.getItem("local_billing_tab");
      if (filterData.dateFilters.startDateActive && local_tab === "0") {
        const filterDataAddon = cloneDeep(filterData);
        filterDataAddon.filters.push({
          filter: "date_column",
          group: "Date Filter",
          values: [{ label: "Due Date", value: "workticket_start_date" }],
        });
        await getBillingList(dispatchBilling, filterDataAddon);
      } else if (local_tab === FILE_INDEX) {
        await getBillingExportList(dispatchBilling, []);
      } else {
        filterData.filters = filterData.filters.filter(
          (item) => item.filter !== "date_column"
        );
        await getBillingList(dispatchBilling, filterData);
      }
    };

    if (filterState.searchString === "" && !filterState.isLoadingFilters) {
      fetchData();
    }
  }, [dispatchBilling, filterState]);

  useEffect(() => {
    if (currentTab !== FILE_INDEX) {
      dispatchTable({ type: "SET_SEARCH", hasSearch: true });
      dispatchTable({
        type: "UPDATE_COLUMNS",
        columns: currentTab === "0" ? columns : columnsReady,
      });
    } else {
      dispatchTable({ type: "SET_SEARCH", hasSearch: false });
      dispatchTable({ type: "UPDATE_COLUMNS", columns: columnsExport });
    }
    const billingData = billingList[currentTab] ?? [];
    dispatchTable({ type: "UPDATE_ROWS", rows: billingData });
  }, [dispatchTable, billingList, currentTab]);

  useEffect(() => {
    if (!billingListLoading) {
      const local_tab = localStorage.getItem("local_billing_tab");
      if (!local_tab) {
        dispatchTable({ type: "SET_CURRENT_TAB", currentTab: "1" });
        localStorage.setItem("local_billing_tab", "1");
        return;
      }
      dispatchTable({ type: "SET_CURRENT_TAB", currentTab: local_tab });
    }
  }, [dispatchTable, billingListLoading]);

  const handleTabChange = (event, newTab) => {
    const fetchData = async () => {
      if (currentTab === SEARCH_INDEX) {
        dispatchFilters({
          type: "SET_SEARCH_STRING",
          searchString: "",
        });
      }

      if (newTab !== FILE_INDEX) {
        dispatchTable({ type: "SET_SEARCH", hasSearch: true });
        dispatchBilling({
          type: "SET_LOADING",
          billingListLoading: true,
        });
        const filterData = getFilterData(filterState);

        if (filterData.dateFilters.startDateActive && newTab === "0") {
          const filterDataAddon = cloneDeep(filterData);
          filterDataAddon.filters.push({
            filter: "date_column",
            group: "Date Filter",
            values: [{ label: "Due Date", value: "workticket_start_date" }],
          });
          await getBillingList(dispatchBilling, filterDataAddon);
        } else {
          await getBillingList(dispatchBilling, filterData);
        }
      } else if (newTab === FILE_INDEX) {
        dispatchTable({ type: "SET_SEARCH", hasSearch: false });
        await getBillingExportList(dispatchBilling, billingList);
      }
      localStorage.setItem("local_billing_tab", newTab);
      dispatchTable({ type: "SET_CURRENT_TAB", currentTab: newTab });
    };
    fetchData();
    setTimeout(() => {
      dispatchTable({
        type: "SET_TAB_UPDATED",
        tabUpdated: null,
      });
    }, 3000);
  };

  const handleModuleChange = (event, newTab) => {
    const { history } = props;
    if (newTab === "1") {
      history.push(`/financial/report/account-receivable`);
    }
  };

  const handleScrollClick = useCallback(async () => {
    const billingData = billingList[currentTab] ?? [];
    if (billingCount[currentTab] <= billingData.length || loadingMore) {
      return;
    }

    try {
      setLoadingMore(true);
      if (FILE_INDEX !== currentTab) {
        const filterData = getFilterData(filterState);
        if (filterData.dateFilters.startDateActive && currentTab === "0") {
          const filterDataAddon = cloneDeep(filterData);
          filterDataAddon.filters.push({
            filter: "date_column",
            group: "Date Filter",
            values: [{ label: "Due Date", value: "workticket_start_date" }],
          });
          await getBillingListDataSet(
            billingList,
            billingData.length,
            dispatchBilling,
            currentTab,
            filterDataAddon
          );
        } else {
          await getBillingListDataSet(
            billingList,
            billingData.length,
            dispatchBilling,
            currentTab,
            filterData
          );
        }
      } else {
        await getBillingExportListDataSet(
          billingList,
          billingData.length,
          dispatchBilling,
          currentTab
        );
      }
      const billingDataUpdate = billingList[currentTab] ?? [];
      dispatchTable({ type: "UPDATE_ROWS", rows: billingDataUpdate });
      setLoadingMore(false);
    } catch (e) {
      console.log("Cannot load more data", e);
    }
  }, [
    currentTab,
    billingList,
    billingCount,
    filterState,
    dispatchBilling,
    dispatchTable,
    loadingMore,
  ]);

  const handleRowClick = (event, row) => {
    if (hasRowsClickable) {
      console.log("Click on row event");
    }
    return;
  };

  const handleSearch = (ev, query) => {
    const searchStatus = SEARCH_INDEX;
    if (query) {
      if (ev.key === "Enter" || ev.type === "click") {
        const searchData = async () => {
          setLoadingSearch(true);
          const filterData = getFilterData(filterState);
          const results = await searchBilling(query, filterData);
          const totalResults = [...results[0].list, ...results[1].list];
          // Send result to search tab
          billingList[searchStatus] = totalResults;
          dispatchBilling({
            type: "SET_BILLING_LIST",
            billingList: { ...billingList },
          });

          billingCount[searchStatus] = results[0].count + results[1].count;
          dispatchBilling({
            type: "SET_BILLING_COUNT",
            billingCount: { ...billingCount },
          });

          dispatchFilters({
            type: "SET_SEARCH_STRING",
            searchString: query,
          });

          dispatchTable({ type: "SET_CURRENT_TAB", currentTab: searchStatus });
          setLoadingSearch(false);
        };
        if (query.length < 3) {
          alert("Input at least 3 characters");
          return;
        }
        searchData();
        ev.preventDefault();
      }
    }
    return;
  };

  const handleSearchClear = () => {
    dispatchFilters({
      type: "SET_SEARCH_STRING",
      searchString: "",
    });
    dispatchFilters({
      type: "SET_FILTER_RESULTS",
      filterResults: [],
    });
    const local_tab = localStorage.getItem("local_billing_tab");
    if (!local_tab) {
      dispatchTable({ type: "SET_CURRENT_TAB", currentTab: "1" });
      localStorage.setItem("local_billing_tab", "1");
      return;
    }
    dispatchTable({ type: "SET_CURRENT_TAB", currentTab: local_tab });
  };

  return (
    <Box className={classes.root}>
      {billingListLoading || loadingSearch || currentTab === -1 ? (
        <LoadingStateHorizontal isVisible style={classes.centerLoading} />
      ) : (
        <>
          <Box className={classes.contentContainer}>
            <TableTabs handleTabChange={handleModuleChange} value="0">
              <StyledTab
                disableRipple={true}
                label={<Box className={classes.tabItem}>Account Payable</Box>}
                key="0"
                value="0"
              />
              <StyledTab
                disableRipple={true}
                label={
                  <Box className={classes.tabItem}>Account Receivable</Box>
                }
                key="1"
                value="1"
              />
            </TableTabs>
            <Box className={classes.contentCounter}>
              <Counter>
                {`${billingList[currentTab]?.length} / ${billingCount[currentTab]}`}
              </Counter>
            </Box>
            <TableTabs
              handleTabChange={handleTabChange}
              value={currentTab}
              className={classes.tabsSecondary}
            >
              {billingAPTabOptions
                .filter((item) => item.onTabing)
                .map((option) => {
                  return (
                    <StyledTab
                      label={
                        <Box className={classes.tabItem}>{option.text}</Box>
                      }
                      value={option.tab}
                      key={option.id}
                    />
                  );
                })}
              <StyledTab
                disableRipple={true}
                label={<Box className={classes.tabItem}>Exported</Box>}
                key={FILE_INDEX}
                value={FILE_INDEX}
              />
              {Boolean(filterState.searchString) ? (
                <StyledTab
                  disableRipple={true}
                  label={<Box className={classes.tabItem}>Search</Box>}
                  key={SEARCH_INDEX}
                  value={SEARCH_INDEX}
                />
              ) : null}
            </TableTabs>
            <Box className={classes.rootContainer}>
              <Box className={classes.tableContainer}>
                <Box className={classes.fullHeightTable}>
                  <Table
                    handleRowClick={handleRowClick}
                    newColumns={columns}
                    handleScrollClick={handleScrollClick}
                  >
                    <EnhancedTableToolbar>
                      {selected.length > 0 ? (
                        <ToolbarSelected>
                          <AccountPayablesBulkActions />
                        </ToolbarSelected>
                      ) : (
                        <ToolbarDefault
                          handleSearch={handleSearch}
                          handleSearchClear={handleSearchClear}
                        >
                          {FILE_INDEX !== currentTab ? (
                            <>
                              <AccountPayablesListActions tab={currentTab} />
                              <CalendarFilters />
                              <AccountPayablesFilters />
                            </>
                          ) : null}
                        </ToolbarDefault>
                      )}
                    </EnhancedTableToolbar>
                    {FILE_INDEX !== currentTab && filterState.filters ? (
                      <FiltersAppliedList />
                    ) : null}
                  </Table>
                </Box>
              </Box>
            </Box>
            {loadingMore && (
              <Box className={classes.loadingTable}>
                <LinearProgress color="secondary" />
              </Box>
            )}
          </Box>
        </>
      )}
    </Box>
  );
};

export default withRouter(AccountPayablesTable);
