import React, { useState, useContext, useMemo } from "react";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Skeleton from "@material-ui/lab/Skeleton";
import { ButtonBase } from "components/ui/core/button";
import GlobalUiContext from "contexts/globalUiContext";
import { useWorkticketView } from "contexts/workticketViewContext";
import WorkticketTimekeepingClock from "./workticketTimekeepingClock";
import WorkticketTimekeepingClockPT from "./workticketTimekeepingClockPT";
import WorkticketTimekeepingHistory from "./workticketTimekeepingHistory";
import WorkticketTimekeepingManual from "./workticketTimekeepingManual";
import WorkticketTimekeepingManualMultiple from "./workticketTimekeepingManualMultiple";
import { permissionWorkticket, hasPermission } from "lib/permissions";

import useStyles from "./styles";

const subcontratorTiers = ["subcontractor_crew", "subcontractor"];
const tierTempStaffingAllowed = ["subcontractor", "subcontractor_admin"];
const PORTFOLIO_PROJECT = 3;
const ADDON_PROJECT = 1;
const TEMP_STAFFING_PROJECT = 5;
const QUICK_TICKET_CATEGORY = 0;
const CONTRACT_PERIODIC = 6;

const WorkticketTimekeeping = (props) => {
  const classes = useStyles();
  const { globalUi } = useContext(GlobalUiContext);
  const { permissions } = globalUi;
  const [stateContext] = useWorkticketView();
  const [openTimekeepingReport, setOpenTimekeepingReport] = useState(false);
  const [openTimekeepingManual, setOpenTimekeepingManual] = useState(false);
  const user = JSON.parse(localStorage.getItem("user"));
  const { isLoading, workticket } = stateContext ?? null;

  const isSubTicket = useMemo(() => {
    return workticket?.users
      ? workticket?.users.filter((u) =>
          subcontratorTiers.includes(u.tier_classification)
        ).length
        ? true
        : false
      : false;
  }, [workticket?.users]);

  const handleOpenTimekeepingReport = () => {
    setOpenTimekeepingReport(true);
  };

  const handleCloseTimekeepingReport = () => {
    setOpenTimekeepingReport(false);
  };

  const handleOpenTimekeepingManual = () => {
    setOpenTimekeepingManual(true);
  };

  const handleCloseTimekeepingManual = () => {
    setOpenTimekeepingManual(false);
  };

  if (isLoading) {
    return (
      <Skeleton
        animation="wave"
        variant="rect"
        height={100}
        style={{ marginTop: 20, marginBottom: 20 }}
      />
    );
  }

  if (
    !hasPermission(permissionWorkticket.CLOCK, permissions) &&
    !hasPermission(permissionWorkticket.VIEW_TIMEKEEPING, permissions) &&
    !hasPermission(permissionWorkticket.MANAGE_TIMEKEEPING, permissions)
  ) {
    return null;
  }
  return (
    <Box className={classes.containerTimekeeping}>
      <Typography
        variant="h4"
        className={classes.titleSectionBody}
        gutterBottom
      >
        Timekeeping
      </Typography>
      {hasPermission(permissionWorkticket.CLOCK, permissions) && (
        <Box className={classes.containerTimekeepingClock}>
          {[
            PORTFOLIO_PROJECT,
            ADDON_PROJECT,
            TEMP_STAFFING_PROJECT,
            CONTRACT_PERIODIC,
          ].includes(workticket?.project?.type) ||
          [QUICK_TICKET_CATEGORY].includes(workticket?.workticket_category) ? (
            <WorkticketTimekeepingClockPT />
          ) : (
            <WorkticketTimekeepingClock />
          )}
        </Box>
      )}
      <Grid container spacing={2}>
        {hasPermission(permissionWorkticket.VIEW_TIMEKEEPING, permissions) && (
          <Grid item md={6} xs={12}>
            <ButtonBase
              color="secondary"
              variant="outlined"
              fullWidth={true}
              onClick={handleOpenTimekeepingReport}
            >
              History
            </ButtonBase>
          </Grid>
        )}
        {hasPermission(
          permissionWorkticket.MANUAL_TIMEKEEPING,
          permissions
        ) && (
          <Grid item md={6} xs={12}>
            {![TEMP_STAFFING_PROJECT].includes(workticket?.project?.type) ||
            !isSubTicket ||
            tierTempStaffingAllowed.includes(user.tier_classification) ? (
              <ButtonBase
                color="secondary"
                variant="outlined"
                fullWidth={true}
                onClick={handleOpenTimekeepingManual}
              >
                Manual Entry
              </ButtonBase>
            ) : null}
          </Grid>
        )}
      </Grid>
      <WorkticketTimekeepingHistory
        open={openTimekeepingReport}
        handleClose={handleCloseTimekeepingReport}
        restrictModify={
          ![TEMP_STAFFING_PROJECT].includes(workticket?.project?.type) ||
          !isSubTicket ||
          tierTempStaffingAllowed.includes(user.tier_classification)
            ? false
            : true
        }
      />
      {[ADDON_PROJECT, TEMP_STAFFING_PROJECT, CONTRACT_PERIODIC].includes(
        workticket?.project?.type
      ) || [QUICK_TICKET_CATEGORY].includes(workticket?.workticket_category) ? (
        <WorkticketTimekeepingManualMultiple
          open={openTimekeepingManual}
          handleClose={handleCloseTimekeepingManual}
        />
      ) : (
        <WorkticketTimekeepingManual
          open={openTimekeepingManual}
          handleClose={handleCloseTimekeepingManual}
        />
      )}
    </Box>
  );
};

export default WorkticketTimekeeping;
