import React, { useState, useEffect, useCallback, useContext } from "react";
import moment from "moment";
import { getDistance } from "geolib";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import Typography from "@material-ui/core/Typography";
import { DialogCloseIcon } from "components/ui/core/dialogCloseIcon";
import { DialogTitle } from "components/ui/core/dialogTitle";
import Timer from "components/common/Timer";
import ErrorDialog from "components/ui/dialog/errorDialog";
import LocationIcon from "@material-ui/icons/MyLocation";
import LocationOff from "@material-ui/icons/LocationOff";
import { toast } from "react-toastify";
import { loadGeo, getGeoLocation } from "components/util/geoUtil";
import { convertDateToUnix } from "components/util/timeFormat";
import GlobalUiContext from "contexts/globalUiContext";
import { useWorkticketView } from "contexts/workticketViewContext";
import {
  clockInWorkticketNonStrict,
  clockOutWorkticket,
  updateWorkticketStatus,
  addWorkticketComment,
} from "services/workticketService";
import TicketIcon from "@material-ui/icons/Receipt";
import FormSelect from "components/ui/FormContent/formSelect";
import FormInput from "components/ui/FormContent/formInput";
import {
  convertDateFormatField,
  billingLimitDate,
} from "components/util/timeFormat";
import { DialogMessage } from "components/ui/core/dialogMessage";
import { getUserSchedulesAll, getUserJobSchedules } from "services/userService";
import { logException } from "components/util/logUtil";
import { DaysFromNowStandard } from "components/util/timeFormat";
import useStyles from "./styles";
import { workticketsStatus } from "constants.js";
import { permissionWorkticket, hasPermission } from "lib/permissions";
import Alert from "@material-ui/lab/Alert";
import FormInputDateAdo from "components/ui/FormContent/formInputDateAdo";
import MessageDialog from "components/ui/dialog/messageDialog";
import { getUserSchedules } from "services/userService";
import LoadingStateHorizontal from "components/common/LoadingStateHorizontal/LoadingStateHorizontal";

const WorkticketTimekeepingClockFM = (props) => {
  const classes = useStyles();
  const [stateContext, dispatchContext] = useWorkticketView();
  const { globalUi, dispatchGlobalUi } = useContext(GlobalUiContext);
  const { role, permissions } = globalUi;
  const [time, setTime] = useState(0);
  const [activeInterval, setActiveInterval] = useState(0);
  const [disabled, setDisabled] = useState(false);
  const [hasGeo, setHasGeo] = useState(false);
  const [positionLocation, setPositionLocation] = useState(null);
  const [currentClock, setCurrentClock] = useState(null);
  const [openError, setOpenError] = useState(false);
  const [msgError, setMsgError] = useState("");
  const [openValidation, setOpenValidation] = useState(false);
  const [typeValidation, setTypeValidation] = useState(0);
  const [msgValidation, setMsgValidation] = useState(null);
  const [isLoadingValidation, setIsLoadingValidation] = useState(false);
  const [openClockOut, setOpenClockOut] = useState(false);
  const [isLoadingClockOut, setIsLoadingClockOut] = useState(false);
  const [isClosingClockOut, setIsClosingClockOut] = useState(false);
  const [externalNote, setExternalNote] = useState("");
  const [externalStatus, setExternalStatus] = useState("");
  const [openDone, setOpenDone] = useState(false);
  const [confirmationDate, setConfirmationDate] = useState(false);
  const [dateDone, setDateDone] = useState(null);
  const [confirmationIsLoading, setConfirmationIsLoading] = useState(false);
  const [openSuccess, setOpenSuccess] = useState(false);
  const [msgSuccess, setMsgSuccess] = useState("");
  const [timeTravel, setTimeTravel] = useState(0);
  const [activeTravelInterval, setTravelActiveInterval] = useState(0);
  const [disabledTravel, setDisabledTravel] = useState(false);

  const TEMP_STAFFING_PROJECT = 5;

  const { workticket, isLoading } = stateContext ?? null;

  const markDonePerm = hasPermission(
    permissionWorkticket.MARK_DONE,
    permissions
  );

  const backDatePerm = hasPermission(
    permissionWorkticket.BACK_DATE,
    permissions
  );

  let minLimitDate = undefined;
  let minDateMessage = undefined;
  if (!backDatePerm) {
    minLimitDate = billingLimitDate(5);
    minDateMessage = "Date not allowed, contact financial/admin team.";
  }

  const workticketsDisplayStatusClock = workticketsStatus.filter((item) =>
    [1, 4].includes(item.value)
  );

  const handleClockOutConfirmationClose = () => {
    setIsClosingClockOut(true);
  };

  const handleMarkDone = async () => {
    const doneStatus = 2;
    const completedStatus = 4;
    try {
      setConfirmationIsLoading(true);
      let data = { status: doneStatus };
      if (hasPermission(permissionWorkticket.SET_COMPLETED_DATE, permissions)) {
        data = {
          ...data,
          completed_date: moment(dateDone).format("YYYY-MM-DD"),
        };
      }
      const position = await getGeoLocation();
      if (position) {
        data = {
          ...data,
          latitude: position.coords.latitude,
          longitude: position.coords.longitude,
        };
      }
      await updateWorkticketStatus(workticket.id, data);
      workticket.status = hasPermission(
        permissionWorkticket.MARK_DONE,
        permissions
      )
        ? doneStatus
        : completedStatus;
      dispatchContext({
        type: "SET_WORKTICKET",
        workticket: workticket,
      });
      setConfirmationIsLoading(false);
      setOpenDone(false);
      setMsgSuccess(`Workticket has been mark verified.`);
      setOpenSuccess(true);
      setDateDone(null);
      setConfirmationDate(false);
    } catch (e) {
      logException(e, "Cannot mark workticket as verified");
    }
  };

  useEffect(() => {
    const executeGeo = async () => {
      if (loadGeo()) {
        const position = await getGeoLocation();
        if (position) {
          setHasGeo(true);
          setPositionLocation(position);
        }
      } else {
        setHasGeo(false);
      }
    };
    executeGeo();
  }, []);

  const clockStart = useCallback(
    async (entry) => {
      const entryInput = { ...entry, workticket: { ...workticket } };
      dispatchGlobalUi({
        type: "SET_TIMEKEEPING",
        timekeeping: entryInput,
      });
      setCurrentClock(entry);
      const start = convertDateToUnix(entry.clock_in);
      setDisabledTravel(true);
      const interval = setInterval(() => {
        setTime(Date.now() - start);
      }, 1000);
      setActiveInterval(interval);

      // Change status of workticket to In Progress if Not Started
      if (workticket.status === 0) {
        const data = {
          status: 1,
        };
        workticket.status = data.status;
        dispatchContext({
          type: "SET_WORKTICKET",
          workticket: workticket,
        });
        await updateWorkticketStatus(workticket.id, data);
      }
    },
    [workticket, dispatchContext, dispatchGlobalUi]
  );

  const positionData = (drive, skipValFlag) => {
    return {
      drive,
      skipFlag: skipValFlag,
      latitude: positionLocation ? positionLocation.coords.latitude : null,
      longitude: positionLocation ? positionLocation.coords.longitude : null,
    };
  };

  const clockStartTravel = useCallback(
    async (entry) => {
      const entryInput = { ...entry, workticket: { ...workticket } };
      dispatchGlobalUi({
        type: "SET_TIMEKEEPING",
        timekeeping: entryInput,
      });
      setCurrentClock(entry);
      const start = convertDateToUnix(entry.drive_in);
      setDisabled(true);
      const interval = setInterval(() => {
        setTimeTravel(Date.now() - start);
      }, 1000);
      setTravelActiveInterval(interval);

      // Change status of workticket to In Progress if Not Started
      if (workticket.status === 0) {
        try {
          const data = {
            status: 1,
          };
          workticket.status = data.status;
          dispatchContext({
            type: "SET_WORKTICKET",
            workticket: workticket,
          });
          await updateWorkticketStatus(workticket.id, data);
        } catch (e) {
          console.log("Cannot update workticket status");
        }
      }
    },
    [workticket, dispatchContext, dispatchGlobalUi]
  );

  useEffect(() => {
    // Clock in verification on this workticket
    const runningClocks = async () => {
      if (!isLoading) {
        try {
          let isActive = false;
          const user = JSON.parse(localStorage.getItem("user"));
          const result = await getUserSchedulesAll(user.id);
          const data = result.data.data;
          const entry = data.entries.find(
            (item) =>
              item.workticket.id === workticket.id &&
              !item.clock_hours &&
              item.user.id === user.id
          );
          if (entry && entry.clock_in && entry.user.id === user.id) {
            clockStart(entry);
            isActive = true;
          } else {
            if (activeInterval) {
              clearInterval(activeInterval);
            }

            setTime(0);
          }
          if (entry && entry.drive_in && entry.user.id === user.id) {
            clockStartTravel(entry);
            isActive = true;
          } else {
            if (activeTravelInterval) {
              clearInterval(activeTravelInterval);
            }

            setTimeTravel(0);
          }
          if (!isActive) {
            setCurrentClock(null);
            setDisabledTravel(false);
          }
        } catch (e) {
          logException(e, "No timekeeping");
        }
      }
    };
    if (
      (globalUi.timekeeping && !currentClock) ||
      (!globalUi.timekeeping && currentClock) ||
      currentClock?.workticket?.id !== workticket.id
    ) {
      runningClocks();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    isLoading,
    workticket.id,
    globalUi.timekeeping,
    clockStart,
    clockStartTravel,
  ]);

  useEffect(() => {
    return () => {
      clearInterval(activeInterval);
      clearInterval(activeTravelInterval);
    };
  }, [activeInterval, activeTravelInterval]);

  const handleStart = async (skipValFlag = 0) => {
    setDisabled(true);
    const user = JSON.parse(localStorage.getItem("user"));
    const resultClockActive = await getUserJobSchedules(
      user.id,
      workticket.job.id
    );

    if (
      resultClockActive.data.data.entries.length &&
      resultClockActive.data.data.entries[0].workticket &&
      resultClockActive.data.data.entries[0].workticket.id !== workticket.id
    ) {
      setMsgError(
        `User is already running a clock on ${resultClockActive.data.data.entries[0].workticket.number}.`
      );
      setOpenError(true);
      return false;
    }

    try {
      const data = positionData(0, skipValFlag);
      if ((!data.latitude || !data.latitude) && skipValFlag === 0) {
        setMsgValidation({
          title: "Not Detected",
          content:
            "We were not able to detect your location. Please enable your location to submit your request.",
        });
        setTypeValidation(1);
        setOpenValidation(true);
        return;
      }

      if (skipValFlag === 0) {
        const resultDistanceGeo = getDistance(
          {
            latitude: data.latitude,
            longitude: data.longitude,
          },
          {
            latitude: workticket.job.latitude,
            longitude: workticket.job.longitude,
          }
        );

        if (resultDistanceGeo > 800 && skipValFlag === 0) {
          setMsgValidation({
            title: "Outside of Range",
            content:
              "Your location is outside of the accepted geofence range. This will result in ticket not being verified as completed at site.",
          });
          setTypeValidation(1);
          setOpenValidation(true);
          return;
        }
      }
      const result = await clockInWorkticketNonStrict(workticket.id, data);
      const schedule = result.data.data.schedule;
      const entry = schedule.entries.find(
        (item) => !item.clock_hours && item.user.id === user.id
      );
      if (entry.user.id === user.id) {
        clockStart(entry);
      }
      setDisabled(false);
      setIsLoadingValidation(false);
    } catch (e) {
      const daysDiff = DaysFromNowStandard(workticket.start_date);
      let errorMsg = e.response.data.message;
      if (daysDiff > 0) {
        errorMsg =
          "This ticket is overdue, and past the acceptable timeframe of completion. Please reach out to Encompass Support to update the status.";
      }
      setMsgError(errorMsg);
      setOpenError(true);
    }
  };

  const handleSubmitClick = () => {
    setIsLoadingClockOut(true);
    if ([2, 4].includes(externalStatus)) {
      handleStop();
    } else {
      handleStop(1, 1);
    }
  };

  const handleStop = async (skipValFlag = 0, updateStatus = 0) => {
    try {
      if (!currentClock) {
        toast.warning("No clock is running.");
        return;
      }

      const data = {
        drive: 0,
        skipFlag: skipValFlag,
        latitude: positionLocation ? positionLocation.coords.latitude : null,
        longitude: positionLocation ? positionLocation.coords.longitude : null,
        external_status: externalStatus,
        resolution: externalNote,
      };

      if ((!data.latitude || !data.latitude) && skipValFlag === 0) {
        setMsgValidation({
          title: "Not Detected",
          content:
            "We were not able to detect your location. Please enable your location to submit your request.",
        });
        setTypeValidation(2);
        setOpenValidation(true);
        return;
      }
      if (skipValFlag === 0) {
        const resultDistanceGeo = getDistance(
          {
            latitude: data.latitude,
            longitude: data.longitude,
          },
          {
            latitude: workticket.job.latitude,
            longitude: workticket.job.longitude,
          }
        );

        if (resultDistanceGeo > 800 && skipValFlag === 0) {
          setMsgValidation({
            title: "Outside of Range",
            content:
              "Your location is outside of the accepted geofence range. This will result in ticket not being verified as completed at site.",
          });
          setTypeValidation(2);
          setOpenValidation(true);
          return;
        }
      }

      clearInterval(activeInterval);
      setTime(0);
      setDisabledTravel(false);
      setDisabled(true);
      await clockOutWorkticket(
        workticket.id,
        currentClock.workticket_schedule_id,
        data
      );
      setCurrentClock(null);
      if (globalUi.timekeeping) {
        if (globalUi.timekeeping.workticket.id === workticket.id) {
          dispatchGlobalUi({
            type: "SET_TIMEKEEPING",
            timekeeping: null,
          });
        }
      }
      if (data.status === "Completed") {
        // Mark ticket as verified optimistic
        workticket.status = 2;
        dispatchContext({
          type: "SET_WORKTICKET",
          workticket: workticket,
        });
      }
      if (updateStatus === 1) {
        handleAddNote();
        if (externalStatus === 2) {
          setOpenDone(true);
        }
        setOpenClockOut(false);
        setIsClosingClockOut(false);
        setIsLoadingClockOut(false);
      }
      setDisabled(false);
      setIsLoadingValidation(false);
    } catch (e) {
      logException(e, "Cannot stop clock");
    }
  };

  const handleValidationContinue = async () => {
    setIsLoadingValidation(true);
    if (typeValidation === 1) {
      // Clock In
      await handleStart(1);
    } else if (typeValidation === 2) {
      // Clock Out
      if (externalStatus) {
        await handleStop(1, 1);
      } else {
        await handleStop(1);
      }
    }
    setOpenValidation(false);
  };

  const handleValidationClose = () => {
    setOpenValidation(false);
    setIsLoadingValidation(false);
    setDisabled(false);
  };

  const handleCloseError = () => {
    setOpenError(false);
  };

  const handleClockOutOpen = () => {
    setExternalStatus("");
    setExternalNote("");
    if (workticket?.project?.type !== TEMP_STAFFING_PROJECT) {
      setOpenClockOut(true);
    } else {
      handleStop(1, 1);
    }
  };

  const handleChangeStatusPT = (event) => {
    setExternalStatus(event.value);
  };

  const handleChangeNotePT = (event, value) => {
    setExternalNote(event.value);
  };

  const handleClockOutConfirmationRevert = () => {
    setIsClosingClockOut(false);
  };

  const handleClockOutClose = () => {
    setOpenClockOut(false);
    setExternalStatus("");
    setExternalNote("");
    setIsClosingClockOut(false);
  };

  const handleAddNote = async () => {
    if (externalNote !== "") {
      const data = {
        body: externalNote,
      };
      try {
        const result = await addWorkticketComment(workticket.id, data);
        dispatchContext({
          type: "SET_WORKTICKET",
          workticket: {
            ...workticket,
            comments: result.data.data.workticket.comments,
          },
        });
        setExternalNote("");
      } catch (e) {
        logException(e, "Cannot add comment");
      }
    }
  };

  const handleDoneClose = () => {
    setOpenDone(false);
    setDateDone(null);
    setConfirmationDate(false);
  };

  const handleAddCompleteDate = () => {
    setDateDone(workticket.sub_completed_date);
  };

  const handleComplete = async () => {
    try {
      handleMarkDone();
    } catch (e) {
      logException(e, "Cannot mark completed");
    }
  };

  const handleCloseSuccess = () => {
    setOpenSuccess(false);
  };

  const handleMarkDoneConfirmation = () => {
    setConfirmationDate(true);
  };

  const handleStartTravel = async () => {
    const user = JSON.parse(localStorage.getItem("user"));
    const resultClockActive = await getUserSchedules(user.id);

    if (
      resultClockActive.data.data.entries.length &&
      resultClockActive.data.data.entries[0].workticket.id !== workticket.id
    ) {
      setMsgError(
        `User is already running a clock on ${resultClockActive.data.data.entries[0].workticket.number}.`
      );
      setOpenError(true);
      return false;
    }
    try {
      const data = positionData(1);
      const result = await clockInWorkticketNonStrict(workticket.id, data);
      const schedule = result.data.data.schedule;
      const entry = schedule.entries.find(
        (item) => !item.clock_hours && item.user.id === user.id
      );
      if (entry.user.id === user.id) {
        clockStartTravel(entry);
      }
    } catch (e) {
      setMsgError("Cannot clock In");
      setOpenError(true);
    }
  };

  const handleStopTravel = async () => {
    try {
      if (!currentClock) {
        console.log("No clock is running");
        return;
      }
      clearInterval(activeTravelInterval);
      setTimeTravel(0);
      setDisabled(false);
      const data = positionData(1);
      await clockOutWorkticket(
        workticket.id,
        currentClock.workticket_schedule_id,
        data
      );
      setCurrentClock(null);
      if (globalUi.timekeeping) {
        if (globalUi.timekeeping.workticket.id === workticket.id) {
          dispatchGlobalUi({
            type: "SET_TIMEKEEPING",
            timekeeping: null,
          });
        }
      }
    } catch (e) {
      logException(e, "Cannot stop travel clock");
    }
  };
  return (
    <>
      {hasGeo && <LocationIcon className={classes.locationClock} />}
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <Timer
            handleStart={() => handleStart()}
            handleStop={() => handleClockOutOpen()}
            time={time}
            disabled={
              [2, 3, 4, 5].includes(workticket.status) ||
              parseInt(workticket?.external?.approval) === 0
                ? true
                : disabled
            }
          />
        </Grid>
        {role.is_dispatch ? (
          <Grid item xs={12} sm={6}>
            <Timer
              handleStart={() => handleStartTravel()}
              handleStop={() => handleStopTravel()}
              time={timeTravel}
              travel={true}
              disabled={
                [2, 3, 4, 5].includes(workticket.status) ||
                parseInt(workticket?.external?.approval) === 0
                  ? true
                  : disabledTravel
              }
            />
          </Grid>
        ) : null}
      </Grid>
      <Dialog
        open={openValidation}
        aria-labelledby="dialog-title"
        aria-describedby="dialog-description"
        maxWidth={"xs"}
        fullWidth={true}
      >
        <DialogContent className={classes.wrapperDialog}>
          {!isLoadingValidation ? (
            <>
              <DialogCloseIcon handleClose={handleValidationClose} />
              <DialogTitle
                title={`Location ${msgValidation?.title}`}
                icon={<LocationOff fontSize="large" />}
              />
              <Box className={classes.wrapperDialogValidation}>
                <Typography variant="body2">
                  {msgValidation?.content}
                </Typography>
              </Box>
            </>
          ) : (
            <Box className={classes.containerDialogLoader}>
              <LoadingStateHorizontal isVisible style={classes.centerLoading} />
            </Box>
          )}
        </DialogContent>
        {!isLoadingValidation ? (
          <DialogActions className={classes.wrapperDialogAction}>
            <Button
              onClick={handleValidationClose}
              className={classes.buttonOutlinedDialog}
              variant="outlined"
              size="large"
              color="secondary"
            >
              Go Back
            </Button>
            {msgValidation?.title !== "Not Detected" ? (
              <Button
                onClick={handleValidationContinue}
                className={classes.buttonDialog}
                color="secondary"
                variant="contained"
                size="large"
              >
                Continue
              </Button>
            ) : null}
          </DialogActions>
        ) : null}
      </Dialog>
      <Dialog
        open={openDone}
        aria-labelledby="dialog-title"
        aria-describedby="dialog-description"
        maxWidth={"xs"}
        fullWidth={true}
      >
        <DialogContent className={classes.wrapperDialog}>
          <DialogCloseIcon handleClose={handleDoneClose} />
          <DialogTitle title="Workticket" icon={<TicketIcon />} />
          {hasPermission(
            permissionWorkticket.SET_COMPLETED_DATE,
            permissions
          ) ? (
            <>
              {confirmationDate && dateDone ? (
                !confirmationIsLoading ? (
                  <DialogMessage>
                    Are you sure, the date{" "}
                    {moment(dateDone).format("MM/DD/YYYY")} is the date and
                    month that billing will be allocated to?
                  </DialogMessage>
                ) : (
                  <Box className={classes.formContainer}>
                    <LoadingStateHorizontal
                      isVisible
                      style={classes.centerLoading}
                    />
                  </Box>
                )
              ) : (
                <>
                  <DialogMessage>
                    Are you sure you want to mark workticket as{" "}
                    {markDonePerm ? `verified` : `completed`}?
                  </DialogMessage>
                  {workticket.sub_completed_date && (
                    <Alert
                      severity="info"
                      className={classes.alertContainer}
                      action={
                        <Button
                          color="primary"
                          size="small"
                          variant="contained"
                          onClick={handleAddCompleteDate}
                        >
                          Use Date +
                        </Button>
                      }
                    >
                      Marked completed on{" "}
                      {convertDateFormatField(workticket.sub_completed_date)}
                    </Alert>
                  )}

                  <Box className={classes.formContainer}>
                    <FormInputDateAdo
                      name="date"
                      label="Verified date*"
                      value={dateDone}
                      minDate={minLimitDate}
                      minDateMessage={minDateMessage}
                      handleChange={setDateDone}
                      disableFuture={true}
                      errorMessage={"Please select a valid work verify date"}
                    />
                    <Typography
                      variant="caption"
                      className={classes.formInfoText}
                    >
                      *If applicable, billing will be tied to this time frame.
                    </Typography>
                  </Box>
                </>
              )}
            </>
          ) : (
            <DialogMessage>
              Are you sure you want to mark workticket as{" "}
              {markDonePerm ? `verified` : `completed`}?
            </DialogMessage>
          )}
        </DialogContent>
        <DialogActions className={classes.wrapperDialogAction}>
          <Button
            onClick={handleDoneClose}
            className={classes.buttonOutlinedDialog}
            variant="outlined"
            size="large"
            color="primary"
          >
            Cancel
          </Button>
          {hasPermission(
            permissionWorkticket.SET_COMPLETED_DATE,
            permissions
          ) ? (
            <Button
              onClick={
                confirmationDate ? handleComplete : handleMarkDoneConfirmation
              }
              className={classes.buttonDialog}
              color="secondary"
              variant="contained"
              size="large"
              disabled={!dateDone || confirmationIsLoading}
            >
              {confirmationDate ? `Yes` : `Verify`}
            </Button>
          ) : (
            <Button
              onClick={handleComplete}
              className={classes.buttonDialog}
              color="secondary"
              variant="contained"
              size="large"
            >
              Okay
            </Button>
          )}
        </DialogActions>
      </Dialog>
      <Dialog
        open={openClockOut}
        aria-labelledby="dialog-title"
        aria-describedby="dialog-description"
        maxWidth={"xs"}
        fullWidth={true}
      >
        <DialogContent className={classes.wrapperDialog}>
          {!isLoadingClockOut ? (
            <>
              {!isClosingClockOut ? (
                <DialogCloseIcon
                  handleClose={handleClockOutConfirmationClose}
                />
              ) : null}
              <DialogTitle
                title={`Workticket - ${workticket.number}`}
                icon={<TicketIcon />}
              />
              {!isClosingClockOut ? (
                <Box className={classes.wrapperDialogStatus}>
                  <Box className={classes.wrapperDialogValidation}>
                    <Typography variant="body2">
                      Please update the status of the workticket
                    </Typography>
                  </Box>
                  <FormSelect
                    gridSizes={[{ size: "md", val: 12 }]}
                    name="external_status_clock_out"
                    label="Select status"
                    options={workticketsDisplayStatusClock}
                    value={externalStatus}
                    handleBlur={handleChangeStatusPT}
                    changeValueControlled={true}
                  />
                  {[2, 4].includes(externalStatus) ? (
                    <Box className={classes.wrapperDialogValidation}>
                      <Typography variant="body2">
                        Would you like to add a note, before marking the
                        workticket as completed?
                      </Typography>
                    </Box>
                  ) : null}
                  <FormInput
                    gridSizes={[{ size: "md", val: 12 }]}
                    name="external_note"
                    label="Note"
                    placeholder="Type note here"
                    value={externalNote}
                    multiline={true}
                    rows={4}
                    handleBlur={handleChangeNotePT}
                  />
                </Box>
              ) : (
                <Box className={classes.wrapperDialogStatus}>
                  <Box className={classes.wrapperDialogValidation}>
                    <Typography variant="body2">
                      Are you sure you want to cancel, the status of the
                      workticket won't be updated?
                    </Typography>
                  </Box>
                </Box>
              )}
            </>
          ) : (
            <Box className={classes.containerDialogLoader}>
              <LoadingStateHorizontal isVisible style={classes.centerLoading} />
            </Box>
          )}
        </DialogContent>
        {!isLoadingClockOut && !isClosingClockOut ? (
          <DialogActions className={classes.wrapperDialogAction}>
            <Button
              onClick={handleSubmitClick}
              className={classes.buttonDialog}
              color="secondary"
              variant="contained"
              size="large"
              disabled={isLoadingClockOut || !externalStatus}
            >
              Submit
            </Button>
          </DialogActions>
        ) : null}
        {isClosingClockOut ? (
          <DialogActions className={classes.wrapperDialogAction}>
            <Button
              onClick={handleClockOutConfirmationRevert}
              className={classes.buttonOutlinedDialog}
              color="secondary"
              variant="outlined"
              size="large"
            >
              No
            </Button>
            <Button
              onClick={handleClockOutClose}
              className={classes.buttonDialog}
              color="secondary"
              variant="contained"
              size="large"
            >
              Yes
            </Button>
          </DialogActions>
        ) : null}
      </Dialog>
      <MessageDialog
        open={openSuccess}
        handleClose={handleCloseSuccess}
        title="Success"
        message={msgSuccess}
      />
      <ErrorDialog
        open={openError}
        handleClose={handleCloseError}
        title="Error"
        message={msgError}
      />
    </>
  );
};

export default WorkticketTimekeepingClockFM;
