import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    fontSize: 12,
    paddingTop: 10,
    paddingBottom: 5,
    paddingLeft: 20,
    paddingRight: 20,
    borderBottom: "1px solid #d4d4d4",
  },
  chip: {
    height: 21,
    borderRadius: 3,
    fontSize: 10,
    minWidth: 110,
    fontWeight: "bold",
    "& svg": {
      fontSize: 14,
      color: "#fff",
      marginTop: -1,
      position: "relative",
    },
  },
  chipNotScheduled: {
    backgroundColor: "#78c1ce",
    color: "#fff",
  },
  chipNotStarted: {
    backgroundColor: "#78c1ce",
    color: "#fff",
  },
  chipInProgress: {
    backgroundColor: "#efc945",
    color: "#fff",
  },
  chipDone: {
    backgroundColor: "#9cbb65",
    color: "#fff",
  },
  chipCancelled: {
    backgroundColor: "#f39a3e",
    color: "#fff",
  },
  chipOverdue: {
    backgroundColor: "#F07A8C",
    color: "#fff",
  },
  chipRecalled: {
    backgroundColor: "#FF4965",
    color: "#fff",
  },
  chipEscalated: {
    backgroundColor: "#FF4965",
    color: "#fff",
  },
  chipCorrectiveAction: {
    backgroundColor: "#f07a8c",
    color: "#fff",
  },
  chipEmergency: {
    backgroundColor: "#f51436",
    color: "#fff",
  },
  chipArchived: {
    backgroundColor: "#4d4d4d",
    color: "#fff",
  },
  chipCompleted: {
    backgroundColor: "#0b417e",
    color: "#fff",
  },
  chipRequest: {
    backgroundColor: "#A67EC8",
    color: "#fff",
  },
}));

export default useStyles;
